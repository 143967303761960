"use client";

import React, {
	createContext,
	ReactNode,
	useContext,
} from "react";

// Define the type for backend variants
export interface BackendVariants {
	shipping: {
		delay: {
			value: number;
			unit: string;
		};
	};
	return: {
		delay: {
			value: number;
			unit: string;
		};
	};
}

interface HumanReadableBackendVariants {
	shipping: {
		delay_in_days: number;
		delay_in_hours: number;
	};
	return: {
		delay_in_days: number;
		delay_in_hours: number;
	};
}

// Create a context with a default value
const BackendVariantsContext =
	createContext<
		| HumanReadableBackendVariants
		| undefined
	>(undefined);

// Create a provider component
interface BackendVariantsProviderProps {
	backend_variants: BackendVariants;
	children: ReactNode;
}

export const BackendVariantsProvider: React.FC<
	BackendVariantsProviderProps
> = ({
	backend_variants,
	children,
}) => {
	const humanReadableBackendVariants: HumanReadableBackendVariants =
		{
			shipping: {
				delay_in_days: Math.round(
					backend_variants.shipping
						.delay.value /
						60 /
						60 /
						24
				),
				delay_in_hours: Math.round(
					backend_variants.shipping
						.delay.value /
						60 /
						60
				),
			},
			return: {
				delay_in_days: Math.round(
					backend_variants.return.delay
						.value /
						60 /
						60 /
						24
				),
				delay_in_hours: Math.round(
					backend_variants.return.delay
						.value /
						60 /
						60
				),
			},
		};

	return (
		<BackendVariantsContext.Provider
			value={
				humanReadableBackendVariants
			}>
			{children}
		</BackendVariantsContext.Provider>
	);
};

// Custom hook to use the BackendVariantsContext
export const useBackendVariants =
	(): HumanReadableBackendVariants => {
		const context = useContext(
			BackendVariantsContext
		);
		if (context === undefined) {
			throw new Error(
				"useBackendVariants must be used within a BackendVariantsProvider"
			);
		}
		return context;
	};
