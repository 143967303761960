"use client";
import useSettings from "hooks/useSettings";
import { MainSection as MainSectionType } from "models/MainSection.model";
import { ProductCategory } from "models/ProductCategory.model";
import { SiteLinksSearchBoxJsonLd } from "next-seo";
import {
	useParams,
	usePathname,
} from "next/navigation";
import React, {
	FC,
	createContext,
	useCallback,
	useContext,
	useEffect,
} from "react";
import {
	CATALOG_ROOT,
	FRONTEND_URL,
} from "utils/config";

interface INavigationContext {
	mainSections?: MainSectionType[];
}

interface NavigationProviderProps {
	children?: React.ReactNode;
	mainSections?: MainSectionType[];
}

export const NavigationContext =
	createContext<INavigationContext | null>(
		null
	);

export const NavigationProvider: FC<
	NavigationProviderProps
> = ({ children, mainSections }) => {
	const { settings, updateSettings } =
		useSettings();

	const setMainSectionInLocalStorage = (
		section: ProductCategory | undefined
	) => {
		if (
			!settings?.privacy_consent
				?.essential
		)
			return;
		updateSettings({
			...settings,
			fashion_preference:
				section ?? null,
		});
	};

	const setMainSectionInLocalStorageWithCallback =
		useCallback(
			setMainSectionInLocalStorage,
			[settings, updateSettings]
		);

	const fashionPreference =
		settings?.fashion_preference;

	const params = useParams();

	const pathname = usePathname() ?? "";

	useEffect(() => {
		if (!mainSections) return;

		const pathnames =
			pathname.split("/");

		// delete index where path is empty
		pathnames.forEach((path, index) => {
			if (!path) {
				pathnames.splice(index, 1);
			}
		});

		if (pathnames[0] !== "section")
			return;

		const pathArray =
			typeof params?.handle === "string"
				? params?.handle?.split("/")
				: params?.handle ?? [];

		if (!pathArray.length) return;

		const mainSection =
			mainSections.find((section) => {
				return (
					section.handle ===
					pathArray[0]
				);
			});

		if (
			mainSection &&
			fashionPreference !== mainSection
		) {
			setMainSectionInLocalStorageWithCallback(
				mainSection
			);
		}
	}, [
		mainSections,
		fashionPreference,
		setMainSectionInLocalStorageWithCallback,
		params?.handle,
		pathname,
	]);

	const searchPageUrl = `${FRONTEND_URL}${CATALOG_ROOT}`;

	return (
		<NavigationContext.Provider
			value={{
				mainSections,
			}}>
			<SiteLinksSearchBoxJsonLd
				useAppDir={true}
				url={FRONTEND_URL}
				potentialActions={[
					{
						target: `${searchPageUrl}?searchQuery`,
						queryInput:
							"search_term_string",
					},
				]}
			/>
			{children}
		</NavigationContext.Provider>
	);
};

export const useNavigation = () => {
	const context = useContext(
		NavigationContext
	);

	if (context === null) {
		throw new Error(
			"useNavigation must be used within a NavigationProvider"
		);
	}
	return context;
};
