"use client";

import {
	SxProps,
	Theme,
	styled,
} from "@mui/material";
import {
	BordersProps,
	SpacingProps,
	bgcolor,
	borderRadius,
	compose,
	spacing,
} from "@mui/system";
import NextImage, {
	ImageProps,
} from "next/image";
import { FC, useState } from "react";

const StyledImage = styled(
	NextImage
)<LazyImageProps>(({ theme, sx }) => ({
	transition: theme.transitions.create(
		["filter", "transform"],
		{
			duration:
				theme.transitions.duration
					.standard,
			easing:
				theme.transitions.easing
					.easeInOut,
		}
	),
	...(sx as object), // Apply additional sx styles if provided
}));

export type LazyImageProps =
	ImageProps &
		BordersProps &
		SpacingProps & {
			sx?: SxProps<Theme>;
			applyBlurFilter?: boolean;
		};

const LazyImage = styled<
	FC<LazyImageProps>
>(
	({
		sx,
		applyBlurFilter = true,
		...rest
	}) => {
		const [isLoading, setLoading] =
			useState(true);

		const loadingStyle: SxProps<Theme> =
			applyBlurFilter
				? {
						filter: isLoading
							? "grayscale(1) blur(20px)"
							: "grayscale(0) blur(0px)",
						transform: isLoading
							? "scale(1.1)"
							: "scale(1)",
				  }
				: {};

		return (
			<StyledImage
				sx={{ ...loadingStyle, ...sx }}
				{...rest}
				onLoad={() => setLoading(false)}
			/>
		);
	}
)(
	compose(
		spacing,
		borderRadius,
		bgcolor
	)
);

export default LazyImage;
