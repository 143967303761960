"use client";

import {
	IconButton,
	IconButtonProps,
	Tooltip,
	TooltipProps,
} from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useState } from "react";
import { Paragraph } from "./Typography";
import useDeviceSize from "hooks/useDeviceSize";

export type CustomTooltipProps = {
	description?: string;
	variant?: "help" | "info";
	muiTooltipProps?: Partial<TooltipProps>;
	muiIconButtonProps?: Partial<IconButtonProps>;
};

const CustomTooltip: React.FC<
	CustomTooltipProps
> = ({
	description = "",
	variant = "help",
	muiTooltipProps,
	muiIconButtonProps,
}) => {
	// useState to open tooltip on touch devices with tooltip
	const [openTooltip, setOpenTooltip] =
		useState(false);
	// handle tooltip close
	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};
	// handle tooltip open
	const handleTooltipOpen = (e) => {
		e.stopPropagation();
		setOpenTooltip(true);
	};

	const { downSm: isMobile } =
		useDeviceSize();

	if (!description) return null;

	return (
		<ClickAwayListener
			onClickAway={handleTooltipClose}>
			<div>
				<Tooltip
					placement={
						isMobile ? "top" : "right"
					}
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={openTooltip}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					title={
						<Paragraph component='span'>
							{description}
						</Paragraph>
					}
					arrow
					// add accessibility label
					aria-label={description}
					{...muiTooltipProps}>
					<IconButton
						onClick={handleTooltipOpen}
						{...muiIconButtonProps}>
						{variant === "help" ? (
							<HelpIcon />
						) : (
							<InfoOutlinedIcon />
						)}
					</IconButton>
				</Tooltip>
			</div>
		</ClickAwayListener>
	);
};

export default CustomTooltip;
